import { render, staticRenderFns } from "./referral-approval-page.vue?vue&type=template&id=bfd686ce&scoped=true&"
import script from "./referral-approval-page.vue?vue&type=script&lang=js&"
export * from "./referral-approval-page.vue?vue&type=script&lang=js&"
import style0 from "./referral-approval-page.vue?vue&type=style&index=0&id=bfd686ce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd686ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {KButton,VSkeletonLoader})
